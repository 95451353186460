import {
	Box,
	Checkbox,
	Container,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	Alert,
	Button,
	Typography,
	Link,
	Snackbar,
	useMediaQuery,
	useTheme,
} from '@mui/material'
import {Dialog, DialogTitle, DialogContent, DialogActions} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import {
	useRefinementList,
	useMenu,
	useClearRefinements,
} from 'react-instantsearch-hooks-web'
import {useCookies} from 'react-cookie'
import Opportunities from './Opportunities'
import useCurrentSelection from './helpers/useCurrentSelection'
import getFormUrl, {formBaseUrl} from './helpers/formUrlGenerator'
import {useEffect, useContext} from 'react'
import React, {useState} from 'react'
import {AppContext} from './Context'
import {ListSubheader} from '@mui/material'
import logo from './logo.png'
import InfoIcon from '@mui/icons-material/Info'
import ErrorIcon from '@mui/icons-material/Error'
import cityClusterData from './city_clusters.json'
function Filter() {
	const [cookie] = useCookies(['ref_code'])
	const {shareYourInterests, searchOpportunities} = useContext(AppContext)
	return (
		<Container sx={{mt: '40px', mb: '90px'}} maxWidth="lg">
			<Box sx={{display: 'flex', flexDirection: 'column'}}>
				{searchOpportunities && (
					<>
						<FilterBar />
						<Opportunities />
					</>
				)}
			</Box>
			{!shareYourInterests && (
				<Typography textAlign="center" sx={{mt: '50px'}}>
					Seems you haven't explored the available opportunities yet.
					If you still want to proceed,{' '}
					<Link
						component="a"
						href={`${formBaseUrl}${
							cookie.ref_code === undefined || null
								? ''
								: `?tfa_28800=${cookie.ref_code}`
						}`}
					>
						Click here to record your interest.
					</Link>
				</Typography>
			)}
		</Container>
	)
}
export default Filter

function FilterBar() {
	const [openSnackbar, setOpenSnackbar] = useState(false)
	const [snackbarMessage, setSnackbarMessage] = useState('')
	const [showError, setShowError] = useState(false)
	useEffect(() => {
		window.scrollBy(0, window.innerHeight)
	}, [])

	let {mode, city, cluster, availability} = useCurrentSelection()
	const {selectedSubject, selectedData} = useContext(AppContext)
	let displayMessage = null
	let filterMenuConfig = [{type: 'menu', attribute: 'mode'}]

	if (mode.hasSelected && mode.value === 'Offline') {
		filterMenuConfig.push({type: 'menu', attribute: 'city'})
	}
	if (city.hasSelected) {
		filterMenuConfig.push({
			type: 'list',
			attribute: 'cluster',
			selectedCity: city.value,
		})
	}
	if (
		mode.hasSelected &&
		(mode.value === 'Online' ||
			(mode.value === 'Offline' &&
				cluster.hasSelected &&
				selectedData.current.cluster.value?.length <= 3))
	) {
		filterMenuConfig.push({type: 'list', attribute: 'availability'})
	}

	let filterMenus = filterMenuConfig.map(menu => {
		let component
		if (menu.type === 'menu') {
			component = <FilterMenu attribute={menu.attribute} />
		} else {
			component = (
				<FilterList
					attribute={menu.attribute}
					selectedCity={menu.selectedCity}
				/>
			)
		}
		return (
			<Grid item xs={1} key={menu.attribute}>
				{component}
			</Grid>
		)
	})

	switch (filterMenus.length) {
		case 1:
			displayMessage =
				"Let's get started by selecting your volunteering preferences for mode."
			selectedSubject.current = []
			break
		case 2:
			displayMessage = 'Now select your city volunteering preferences.'
			if (mode.value === 'Online')
				displayMessage =
					'Now select your availability volunteering preferences for mode.'
			if (mode.value === 'Online' && availability.hasSelected)
				displayMessage =
					'Here is a list of Opportunities based on selected preferences. Select Opportunities (Max. 2 Subjects) and Apply.'
			selectedSubject.current = []
			break
		case 3:
			displayMessage =
				'Now select your city cluster volunteering preferences.'
			selectedSubject.current = []
			break
		case 4:
			displayMessage =
				'Here is a list of Opportunities based on selected preferences. Select Opportunities (Max. 2 Subjects) and Apply.'
			if (availability.hasSelected === false)
				displayMessage =
					'Now select your availability volunteering preferences for mode.'
			selectedSubject.current = []
			break
		default:
			displayMessage = null
	}

	useEffect(() => {
		if (
			cluster.hasSelected &&
			selectedData.current.cluster.value?.length > 3
		) {
			displayMessage =
				'Sorry, you can shortlist a maximum of 3 clusters only.'
			setShowError(true)
		} else {
			setShowError(false)
		}
	}, [cluster.hasSelected, selectedData.current.cluster.value])

	useEffect(() => {
		if (displayMessage) {
			setSnackbarMessage(displayMessage)
			setOpenSnackbar(true)
		}
	}, [displayMessage])

	const handleCloseSnackbar = (event, reason) => {
		if (reason === 'clickaway') {
			return
		}
		setOpenSnackbar(false)
	}

	return (
		<>
			<Box
				container
				sx={{
					display: 'flex',
					flexDirection: {xs: 'column', sm: 'column', md: 'row'},
					maxWidth: {md: '94%'},
				}}
			>
				<Grid
					container
					columns={{xs: 1, sm: 2, md: 4}}
					spacing={{xs: 2, sm: 3, md: 1}}
				>
					{filterMenus}
				</Grid>
				{availability.hasSelected && <CustomClearRefinements />}
			</Box>

			<Snackbar
				anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
				open={openSnackbar}
				autoHideDuration={6000}
				onClose={handleCloseSnackbar}
				message={
					<span style={{display: 'flex', alignItems: 'center'}}>
						{showError ? (
							<ErrorIcon
								style={{marginRight: '8px', color: '#f44336'}}
							/>
						) : (
							<InfoIcon
								style={{marginRight: '8px', color: '#2196f3'}}
							/>
						)}
						{snackbarMessage}
					</span>
				}
				sx={{
					bottom: {xs: '80px', sm: '100px', md: '60px', lg: '120px'},
				}}
			/>
		</>
	)
}
function FilterList({attribute, selectedCity}) {
	const {items, refine} = useRefinementList({attribute})

	const [openSnackbar, setOpenSnackbar] = useState(false)
	const [snackbarMessage, setSnackbarMessage] = useState('')
	const {selectedData} = useContext(AppContext)
	const handleRefine = value => {
		if (attribute === 'cluster') {
			const currentSelectedClusters =
				selectedData.current.cluster.value || []
			if (currentSelectedClusters.includes(value)) {
				refine(value)
			} else if (currentSelectedClusters.length < 3) {
				refine(value)
			} else {
				setSnackbarMessage(
					'Sorry, you can shortlist a maximum of 3 clusters only.'
				)
				setOpenSnackbar(true)
			}
		} else {
			refine(value)
		}
	}
	const handleCloseSnackbar = (event, reason) => {
		if (reason === 'clickaway') {
			return
		}
		setOpenSnackbar(false)
	}
	return (
		<>
			<SelectField
				attribute={attribute}
				value={items.filter(v => v.isRefined).map(v => v.value)}
				multiple={true}
				items={items}
				refine={handleRefine}
			/>
			<Snackbar
				anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
				open={openSnackbar}
				autoHideDuration={6000}
				onClose={handleCloseSnackbar}
				message={
					<span style={{display: 'flex', alignItems: 'center'}}>
						<ErrorIcon
							style={{marginRight: '8px', color: '#f44336'}}
						/>
						{snackbarMessage}
					</span>
				}
				sx={{
					bottom: {xs: '24px', sm: '40px'},
				}}
			/>
		</>
	)
}

function FilterMenu({attribute}) {
	const {items, refine} = useMenu({attribute})
	return (
		<SelectField
			attribute={attribute}
			value={items.find(v => v.isRefined)?.label ?? ''}
			multiple={false}
			items={items}
			refine={refine}
			upcomingOptions={[]}
		/>
	)
}

function CustomClearRefinements(props) {
	const {refine} = useClearRefinements(props)
	const {selectedSubject} = useContext(AppContext)
	return (
		<Button
			sx={{
				my: '10px',
				marginLeft: '10px',
			}}
			variant="outlined"
			onClick={() => {
				selectedSubject.current = []
				refine()
			}}
		>
			Reset
		</Button>
	)
}

function SelectField({
	multiple,
	attribute,
	value,
	items,
	refine,
	upcomingOptions = [],
}) {
	const [openDialog, setOpenDialog] = useState(false)
	const [selectedUpcoming, setSelectedUpcoming] = useState(null)
	const [isOpen, setIsOpen] = useState(false)

	const handleUpcomingSelect = item => {
		setSelectedUpcoming(item)
	}

	const handleCloseDialog = () => {
		setOpenDialog(false)
		setSelectedUpcoming(null)
	}

	const handleNotifyMe = () => {
		window.open('https://www.tfaforms.com/4971036', '_blank')
		handleCloseDialog()
	}

	const handleClose = () => {
		setIsOpen(false)
	}

	const handleOpen = () => {
		setIsOpen(true)
	}
	const {selectedData} = useContext(AppContext)

	const isClusterAttribute = attribute === 'cluster'
	const selectedCity = selectedData.current.city?.value

	const availableItems = items.map(item => item.label)
	const allItems =
		isClusterAttribute && selectedCity
			? cityClusterData[selectedCity]
			: attribute === 'city'
			? Object.keys(cityClusterData)
			: []

	const upcomingItems = allItems.filter(
		item => !availableItems.includes(item)
	)
	return (
		<FormControl fullWidth>
			<InputLabel
				id={`${attribute}-menu`}
				style={{textTransform: 'capitalize'}}
			>
				{attribute}
			</InputLabel>
			<Select
				labelId={`${attribute}-menu`}
				id={`${attribute}-select`}
				value={value}
				renderValue={value =>
					Array.isArray(value) ? value.join(', ') : value
				}
				label={attribute}
				multiple={multiple}
				open={isOpen}
				onClose={handleClose}
				onOpen={handleOpen}
				sx={{
					'& .MuiOutlinedInput-notchedOutline': {
						borderColor: 'rgba(0, 0, 0, 0.23)',
					},
					'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
						borderColor: 'primary.main',
					},
					'&:hover .MuiOutlinedInput-notchedOutline': {
						borderColor: 'rgba(0, 0, 0, 0.87)',
					},
					'& .MuiSelect-select': {
						paddingRight: '32px !important',
					},
				}}
			>
				{/* {isOpen && (
			<MenuItem
			  style={{
				position: 'sticky',
				top: 0,
				zIndex: 1,
				backgroundColor: '#fff',
				display: 'flex',
				justifyContent: 'flex-end',
			  }}
			>
			  <Button
				onClick={handleClose}
				style={{ minWidth: 'auto', padding: '4px' }}
			  >
				<CloseIcon />
			  </Button>
			</MenuItem>
		  )} */}
				<ListSubheader
					style={{
						fontWeight: 'bold',
						color: '#6b7280',
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					<span>Available</span>
					<Button
						onClick={handleClose}
						style={{
							minWidth: 'auto',
							padding: '4px',
							color: '#6b7280',
						}}
					>
						<CloseIcon />
					</Button>
				</ListSubheader>
				{items.map(item => (
					<MenuItem
						key={item.label}
						value={item.label}
						onClick={() => {
							refine(item.value)
							if (!multiple) handleClose()
						}}
						style={{
							padding: '8px 16px',
							'&:hover': {backgroundColor: '#f3f4f6'},
						}}
					>
						{multiple && (
							<Checkbox
								checked={item.isRefined}
								style={{marginRight: '8px'}}
								color="primary"
							/>
						)}
						<span style={{flexGrow: 1}}>{item.label}</span>
						<span style={{color: '#6b7280', fontSize: '0.875rem'}}>
							({item.count})
						</span>
					</MenuItem>
				))}
				{upcomingItems.length > 0 && (
					<>
						<ListSubheader
							style={{
								fontWeight: 'bold',
								color: '#6b7280',
								marginTop: '8px',
							}}
						>
							Upcoming
						</ListSubheader>
						{upcomingItems.map(item => (
							<MenuItem
								key={item}
								value={item}
								onClick={() => handleUpcomingSelect(item)}
								style={{
									padding: '8px 16px',
									'&:hover': {backgroundColor: '#f3f4f6'},
								}}
							>
								{multiple && (
									<Checkbox
										style={{marginRight: '8px'}}
										color="primary"
									/>
								)}
								<span style={{flexGrow: 1}}>{item}</span>
							</MenuItem>
						))}
					</>
				)}
			</Select>
		</FormControl>
	)
}
